<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Edit Credit')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <div
      class="d-flex justify-content-between align-items-center mt-1"
    >
      <div v-if="credit!==null">
        <h6 class="text-muted font-weight-bolder">
          {{ $t('Amount') }}
        </h6>
        <h3 class="mb-0">
          {{ credit.amount }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">
          {{ $t('Pending') }}
        </h6>
        <h3 class="mb-0">
          {{ credit.pending }}
        </h3>
      </div>
    </div>
    <hr>
    <validation-observer ref="formData">

      <b-row class="mt-1 m-2">
        <b-col cols="12">
          <b-form-group
            :label="$t('Amount')"
            label-for="amount"
          >
            <validation-provider
              #default="{ errors }"
              name="Amount"
              vid="amount"
              rules="required"
            >
              <!-- <b-form-input
            id="amount"
            v-model="amount"
            :placeholder="$t('Amount')"
          /> -->
              <cleave
                id="amount"
                v-model="amount"
                class="form-control"
                :raw="false"
                :options="optionCleave.number"
                :placeholder="$t('Amount')"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">

        <div
          class="float-right"
        >

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-2"
            @click="submit"
          >
            {{ $t('Accept') }}
            <feather-icon
              icon="CheckIcon"
              size="12"
            />
          </b-button>

        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    Cleave,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataWalletId: null,
      amount: '',
      credit: {
        amount: '',
        pending: '',
        serviceAreaId: null,
      },
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            item, credit,
          } = this.data
          this.dataId = item._id
          this.dataWalletId = credit._id
          this.amount = credit.amount
          this.credit = credit
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.dataWalletId = null
      this.amount = ''
    },
    submit() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            walletId: this.dataWalletId,
            amount: this.amount.replaceAll(',', ''),
          }
          store
            .dispatch(`${this.storeModuleName}/editCredit`, obj)
            .then(result => {
              console.log('fetch Success : ', result)
              this.$emit('update:is-modal-active', false)
              this.$emit('update:show', false)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Success'),
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: this.$t('Success'),
                },
              })
            })
            .catch(error => {
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
